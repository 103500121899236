<template>
  <div class="border px-4 pt-4 pb-5 rounded bg-white shadow-sm login-panel">
    <div class="d-flex justify-content-center mb-4">
      <img src="@/assets/img/login/exec.png" alt class="img-fluid" />
    </div>
    <form v-on:submit.prevent="login()">
      <div class="form-group mb-4">
        <label for="username" class="mb-0 secondary-font text-size-small"
          >USERNAME</label
        >
        <input
          type="text"
          class="form-control input-form"
          id="username"
          placeholder="Username"
          v-model="form.username"
        />
        <span
          v-if="errors.username != ''"
          class="text-danger text-size-small text-capitalize"
          >{{ errors.username }}</span
        >
      </div>
      <div class="form-group mb-5">
        <label for="password" class="mb-0 secondary-font text-size-small"
          >PASSWORD</label
        >
        <input
          type="password"
          class="form-control input-form"
          id="password"
          placeholder="Password"
          v-model="form.password"
        />
        <span
          v-if="errors.password != ''"
          class="text-danger text-size-small text-capitalize"
          >{{ errors.password }}</span
        >
        <span
          v-if="errors.auth != ''"
          class="text-danger text-size-small text-capitalize"
          >{{ errors.auth }}</span
        >
      </div>

      <button
        type="submit"
        class="btn c-button-primary d-block full-width text-uppercase"
      >
        Masuk
      </button>
      <!-- <a
					href="#"
					class="mt-4 text-center d-block text-primary-color text-size-small secondary-font"
				>Lupa Password?</a> -->
    </form>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Login'
    };
  },
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      errors: {
        username: '',
        password: '',
        auth: ''
      }
    };
  },
  methods: {
    login() {
      window.axios
        .post(window.base_api + 'login', this.form)
        .then(response => {
          if (response.status == 200) {
            this.$store.commit('saveToken', response.data.token);
            this.getUser();
            this.$router.push({ path: '/disaster' });
          }
        })
        .catch(error => {
          this.clear();
          if (error.response.status == 400) {
            this.errors.auth = error.response.data.error;
          } else if (error.response.status == 422) {
            if (error.response.data.errors.username) {
              this.errors.username = error.response.data.errors.username[0];
            }
            if (error.response.data.errors.password) {
              this.errors.password = error.response.data.errors.password[0];
            }
          }
        });
    },
    getUser() {
      window.axios
        .get(window.base_api + 'user', {
          headers: { Authorization: 'Bearer ' + this.$store.getters.getToken }
        })
        .then(response => {
          if (response.status == 200) {
            this.$store.commit('saveUser', response.data.user);
          }
        });
    },
    clear() {
      this.errors.password = '';
      this.errors.username = '';
      this.errors.auth = '';
    }
  }
};
</script>

<style>
@media (min-width: 768px) and (max-width: 1024px) {
  .login-panel .img-fluid {
    width: 80% !important;
    height: 80% !important;
  }
}
</style>
